import React from "react";
import FooterFAQs from "../../Footer/v2/FooterFAQs";

const VideoSection = ({ video, videoImage, videoThumbnail, title }) => {
  return (
    <div className="gix__video-section-wrapper">
      {video ? (
        <video src={video} autoPlay={false} />
      ) : (
        <img
          className="video-section-image"
          src={videoImage}
          alt="Life At Goken India"
        />
      )}
      <div className="content-wrapper">
        <h2 className="semi-page-heading regularcase-text margin-0">{title}</h2>
      </div>
      {/* <FooterFAQs /> */}
    </div>
  );
};

export default VideoSection;
