import React from "react";
import HalfDescription from "./HalfDescription";

const AssociateStories = ({
  heading,
  stories = [],
  sectionHeading,
  para,
  ctaText,
  ctaLink,
  playImage,
}) => {
  return (
    <div className="gix__associate-stories-wrapper">
      <div className="gix__associate-stories">
        <div className="background-shade"></div>
        <h2 className="semi-page-heading regularcase-text w-90-wrapper">
          {heading}
        </h2>
        <div className="gix__associate-stories-blocks w-90-wrapper">
          {stories?.map(({ name, designation, image }) => (
            <div className="gix__associate-story-block">
              <img src={image} alt="Goken India Associate Story" />
              <div className="hover-in-effect">
                <div className="hover-content-wrapper">
                  <img src={playImage} className="play-button" alt="" />
                  <h3 className="title margin-0">play to listen</h3>
                </div>
              </div>
              <div className="content-wrapper">
                <h2 className="title margin-0 fw-600">{name}</h2>
                <h3 className="semi-title  margin-0 fw-400">{designation}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <HalfDescription
        heading={sectionHeading}
        para={para}
        link={ctaLink}
        linkText={ctaText}
      />
    </div>
  );
};

export default AssociateStories;
