import React from "react";

const AboutBySwapnil = ({ image, testimonial, name, designation }) => {
  return (
    <div className="gix__leadership_section bg-primary">
      <div className="left-image-wrapper">
        <img src={image} alt="" />
      </div>
      <div className="right-content-wrapper">
        <h2 className="title">{testimonial}</h2>
        <h3 className="title margin-0">{name}</h3>
        <h4 className="title margin-0">{designation}</h4>
      </div>
    </div>
  );
};

export default AboutBySwapnil;
