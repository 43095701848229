import React from "react";

const Current = ({ heading, para, images, bgImage }) => {
  return (
    <div
      className="gix__current-plans-wrapper "
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <h2 className="semi-page-heading w-90-wrapper">{heading}</h2>
      <div className="images-wrapper w-90-wrapper">
        {images?.map(({ image, altText, hoverText }) => {
          return (
            <div className="image-wrapper">
              <div className="blue-gradient-overlay"></div>
              <img src={image} alt={altText} />
              <div className="hover-text bg-primary">
                <h3 className="margin-0 semi-title fw-600">
                  {hoverText?.title}
                </h3>
                <h4 className="margin-0 semi-para fw-400">
                  {hoverText?.location}
                </h4>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Current;
