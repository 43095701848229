import React from "react";
import WipeBtnBlack from "../../../components/atoms/buttons/WipeBtnBlack";

const HalfDescription = ({ heading, link, linkText, para }) => {
  return (
    <div className="gix__half_description w-90-wrapper">
      <div className="left-wrapper">
        <h2 className="semi-page-heading regularcase-text margin-0">
          {heading}
        </h2>
        {link && (
          <WipeBtnBlack
            text={linkText}
            url={link}
            // className={"wipe-btn-auto-width"}
          />
        )}
      </div>
      <div className="right-wrapper">
        <p className="para">{para}</p>
      </div>
    </div>
  );
};

export default HalfDescription;
