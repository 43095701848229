import React from "react";
import HalfDescription from "./HalfDescription";

const Impact = ({ title, para, list }) => {
  return (
    <div className="gix__impact-wrapper">
      <HalfDescription heading={title} para={para} />
      <div className="list-wrapper  w-90-wrapper">
        {list?.map(({ text, para, image }) => (
          <div className="list-block">
            <h3 className="title">{text}</h3>
            <p className="para fw-400">{para}</p>
            <img src={image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Impact;
