import React from "react";
import Layout from "../../src/components/layout";
// import Header from "../../src/molecules/GokenIndia/Header";
import SEO from "../../src/components/seo";
import sliderImage1 from "../images/gokenIndia/v2/header_collab.jpg";
import sliderImage2 from "../images/gokenIndia/v2/header_5celeb.jpg";
import sliderImage3 from "../images/gokenIndia/v2/header_team.jpg";
import sliderImage4 from "../images/gokenIndia/v2/header_life.jpg";
import swapnilsImage from "../images/gokenIndia/v2/swapnil_gawade.jpg";
import blackArrow from "../images/gokenIndia/v2/blackArrow.svg";
import current1 from "../images/gokenIndia/v2/current_1.jpg";
import current2 from "../images/gokenIndia/v2/current_2.jpg";
import current3 from "../images/gokenIndia/v2/current_3.jpg";
import currentBgImage from "../images/gokenIndia/v2/current-bg.png";
import clientBgImage from "../images/gokenIndia/v2/client-bg.png";
import clientTestimonial1 from "../images/gokenIndia/v2/client_1.jpg";
import models from "../images/gokenIndia/v2/models.jpg";
import adas from "../images/gokenIndia/v2/adas.jpg";
import bridges from "../images/gokenIndia/v2/bridges.jpg";
import hybrid from "../images/gokenIndia/v2/hybrid.jpg";

import swapnilKul from "../images/gokenIndia/v2/swapnil_kulkarni.jpg";
import vincent from "../images/gokenIndia/v2/vincent.jpg";
import swapnil from "../images/gokenIndia/v2/swapnil.jpg";
import aruna from "../images/gokenIndia/v2/aruna.jpg";
import videoSs from "../images/gokenIndia/v2/videoSS.jpg";
// import current from "../images/gokenIndia/v2/current_.jpg";

import playImg from "../images/gokenIndia/v2/play.png";
// import OurStory from "../../src/molecules/GokenIndia/OurStory";
// import AboutImage from "../../src/molecules/GokenIndia/AboutImage";
// import OurClients from "../../src/molecules/GokenIndia/OurClients";
// import HowWeServeClients from "../../src/molecules/GokenIndia/HowWeServeClients";
// import SectionDivider from "../../src/molecules/GokenIndia/SectionDivider";
// import GokensContribution from "../../src/molecules/GokenIndia/GokensContribution";
// import Insights from "../../src/molecules/Insights";
import { useStaticQuery, graphql } from "gatsby";

import mobileImage from "../images/gokenIndia/bg-gix.jpg";
import HeaderTitle from "../molecules/GokenIndia/v2/Header";
import HalfDescription from "../molecules/GokenIndia/v2/HalfDescription";
import AboutBySwapnil from "../molecules/GokenIndia/v2/AboutBySwapnil";
import Impact from "../molecules/GokenIndia/v2/Impact";
import Current from "../molecules/GokenIndia/v2/Current";
import Clients from "../molecules/GokenIndia/v2/Clients";
import ImpactFuture from "../molecules/GokenIndia/v2/ImpactFuture";
import AssociateStories from "../molecules/GokenIndia/v2/AssociateStories";
import VideoSection from "../molecules/GokenIndia/v2/VideoSection";
import FooterFAQs from "../molecules/Footer/v2/FooterFAQs";
import FAQ from "../molecules/GokenIndia/v2/FAQ";

// import StaffingNewTimelineComponent from "../../src/molecules/staffing/staffingNewTimelineComponent";
// import StaffingDescription from "../../src/molecules/staffing/staffingDescription";
// import valueImage from "../images/gokenIndia/GIX__value_addition.png";
// const processData = [
//   {
//     id: 1,
//     heading: "System Requirements",
//     paragraph: `Understand vehicle targets: Eg.. Sporty Feel, Weight Reduction`,
//     image: "",
//     isList: true,
//     listItem: [
//       "Define Functionality",
//       "Help meet regulatory requirements",
//       "VA/VE",
//       "Design Conversion (Metal to plastic, 3D printing/RPT to injection moulding or any other process)",
//     ],
//   },
//   {
//     id: 2,
//     heading: "Component Level Requirements",
//     paragraph:
//       "Vehicle targets reflected in system / component targets: e.g.: Good aesthetics & Stiffness, light-weight",
//     image: "",
//     isList: true,
//     listItem: [
//       "Ensure Fit-Function-Finish",
//       "Material Selection",
//       "Moulding process selection",
//       "BOM generation",
//       "Part Packaging in vehicle",
//     ],
//   },
//   {
//     id: 3,
//     heading: "Product Design",
//     paragraph:
//       "3D design of components to ensure performance by understanding constraints for packaging, manufacturing, assembly (with tolerance stack-up), while achieving cost & weight targets",
//     image: "",
//     isList: true,
//     listItem: [
//       "Product Design concept to release",
//       "3D CAD parametric modelling",
//       "Master section – tooling, assembly, packaging  & regulatory check",
//       "Styling surface analysis",
//       "RPS selection and GD&T application",
//       "Locating & Mounting Scheme (Degree of freedom: 3-2-1 principle)",
//       "3D Annotation",
//     ],
//   },
//   {
//     id: 4,
//     heading: "2D Generation & Report",
//     paragraph:
//       "Generate 2D data with GD&T to ensure seamless manufacturability",
//     image: "",
//     isList: true,
//     listItem: [
//       "2D Drawing ",
//       "Bill Of Material",
//       "Product & tooling feasibility report",
//     ],
//   },
//   {
//     id: 5,
//     heading: "Design Verification & Validation",
//     paragraph: "",
//     image: "",
//     isList: true,
//     listItem: [
//       "DFA & DFM",
//       "DFMEA",
//       "DVP&R",
//       "Tolerance stack-up Analysis",
//       "CAE",
//       "FTR report",
//     ],
//   },
// ];
const pageData = {
  header: {
    title: `Goken <b class="color-in">India`,
    subtitle:
      "This is a dummy text. Write two or three lines about who we are or what we do. Can use a powerful statement too.",
    images: [
      { image: sliderImage1, link: "" },
      { image: sliderImage2, link: "" },
      { image: sliderImage3, link: "" },
      { image: sliderImage4, link: "" },
    ],
  },
  latestUpdate: {
    id: "latestUpdate",
    title: "Make this the Latest Update section",
    description:
      "Type some supporting text that is relevant for the above given data and talks about the impact we have made. Talk about what vision do we have or what inspired us to push to greater limits and doing the best in business. This paragraph should be sufficiently good and lengthy. Think of this way, what is the crux of Goken India? What is representing us as a team? Who are we? What defines us?",
    ctaText: "See More",
    ctaLink: "#",
  },
  leadership: {
    image: swapnilsImage,
    testimonial: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit, amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt, ut labore et dolore magnam aliquam quaerat voluptatem.`,
    name: "Swapnil Gawade",
    designation: " -COO, Goken India ",
  },
  Impact: {
    id: "impact",
    title: "How does Goken India create impact",
    para: "Type some supporting text that is relevant for the above given data and talks about the impact we have made. Talk about what vision do we have or what inspired us to push to greater limits and doing the best in business.",
    points: [
      {
        text: `Engineering Design`,
        image: blackArrow,
        para: "Type some supporting text that is relevant for the above given data and talks about the impact we have made.",
      },
      {
        text: "Cost Engineering",
        image: blackArrow,
        para: "Type some supporting text that is relevant for the above given data and talks about the impact we have made.",
      },
      {
        text: "Automation",
        image: blackArrow,
        para: "Type some supporting text that is relevant for the above given data and talks about the impact we have made.",
      },
      {
        text: "Validation",
        image: blackArrow,
        para: "Type some supporting text that is relevant for the above given data and talks about the impact we have made.",
      },
    ],
  },
  current: {
    id: "current",
    title: "Current with Goken India",
    bgImage: currentBgImage,
    carouselItems: [
      {
        image: current1,
        altText: "Event 1",
        hoverText: {
          title: `Automative engineering exposition`,
          location: "Yokohama, Japan",
        },
      },
      {
        image: current2,
        altText: "Event 2",
        hoverText: {
          title: `Goken India 5 year Celebration`,
          location: "Pune Office, India",
        },
      },
      {
        image: current3,
        altText: "Event 3",
        hoverText: {
          title: `Client visit to our tech center`,
          location: "Pune Office, India",
        },
      },
    ],
  },
  clients: {
    id: "clients",
    title: "Write a sentence about what are our values.",
    para: `Type some supporting text that is relevant for the above given data and talks about the impact we have made. Talk about what vision do we have or what inspired us to push to greater limits and doing the best in business. This paragraph should be sufficiently good and lengthy. Think of this way, what is the crux of Goken India? What is representing us as a team? Who are we? What defines us?`,
    quote: {
      text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
      author: {
        name: "Name Surname",
        designation: "Client Name",
      },
    },
    images: [
      { image: clientTestimonial1, altText: "Goken India Client Testimonial" },
    ],
    bgImage: clientBgImage,
  },
  futureImpact: {
    id: "futureImpact",
    title: "Impact on Future",
    paras: [
      `Type some supporting text that is relevant for the above given data and talks about the impact we have made. `,
      `Type some supporting text that is relevant for the above given data and talks about the impact we have made. `,
      `Type some supporting text that is relevant for the above given data and talks about the impact we have made. `,
    ],
    articles: [
      {
        heading:
          "Models of Engagement: Tailored Staffing Solutions for Engineering Firms",
        para: `At Goken, we understand that every client has unique needs, especially when it comes to staffing.`,
        link: "/insight/staffing-solutions-engineering-firms/",
        linkText: "Read More",
        image: models,
      },
      {
        heading: "Are Hybrids Gaining Traction in the EV Era?",
        para: `Type some supporting text that is relevant for the above given data and talks about the impact we have made. `,
        link: "/insight/green-automotive-trends/hybrids-gaining-traction-ev-market-2024/",
        linkText: "Read More",
        image: hybrid,
      },
      {
        heading:
          "Enhancing Vehicle Safety through ADAS Verification and Validation",
        para: `In recent years, electric vehicles (EVs) have dominated headlines and conversations as the future of automotive transportation.`,
        link: "/insight/enhancing-vehicle-safety-through-adas-verification-and-validation/",
        linkText: "Read More",
        image: adas,
      },
      {
        heading:
          "Goken bridges the gap between Automotive OEM and New Suppliers",
        para: `In today's globalized automotive industry, seamless coordination between international partners is crucial for successful project execution.`,
        link: "/insight/bridging-gaps-cross-cultural-technical-coordination/",
        linkText: "Read More",
        image: bridges,
      },
    ],
    ctaLink: "#",
    ctaText: "View More",
  },
  values: {
    id: "values",
    heading: `Write a sentence about what are our values.`,
    para: "Type some supporting text that is relevant for the above given data and talks about the impact we have made. Talk about what vision do we have or what inspired us to push to greater limits and doing the best in business. This paragraph should be sufficiently good and lengthy. Think of this way, what is the crux of Goken India? What is representing us as a team? Who are we? What defines us?",
    link: "/careers/",
    linkText: "Careers",
  },
  testimonials: {
    id: "associateStories",
    title: "Associate Stories",
    playImage: playImg,
    profiles: [
      {
        name: "Swapnil Kulkarni",
        designation: "Director, Operations & Engineering",
        image: swapnilKul,
      },
      {
        designation: "Assistant Manager, HR & TA",
        name: "Vincent Hrangkhawl",
        image: vincent,
      },
      {
        designation: "Engineering Manager/Technical Expert",
        name: "Aruna Kumara",
        image: aruna,
      },
      {
        designation: "Engineering Manager",
        name: "Swapnil Kulkarni",
        image: swapnil,
      },
    ],
    sectionHeading: "Write a sentence about our culture/ identity.",
    para: `Type some supporting text that is relevant for the above given data and talks about the impact we have made. Talk about what vision do we have or what inspired us to push to greater limits and doing the best in business. This paragraph should be sufficiently good and lengthy. Think of this way, what is the crux of Goken India? What is representing us as a team? Who are we? What defines us?`,
    ctaText: "Apply to jobs",
    ctaLink: "/careers/",
  },
  // identity: {
  //   id: "identity",
  //   title: "Write a sentence about our culture/identity",
  //   description:
  //     "This section explains our culture and identity. Lorem ipsum dolor sit amet, consectetur adipiscing elit. We can elaborate further in this space.",
  // },
  videoSection: {
    id: "videoSection",
    title: "A Video Will Play",
    videoUrl: "",
    image: videoSs,
  },
  faq: {
    id: "faq",
    title: "Frequently Asked Questions",
    questions: [
      {
        question: "What does Goken India do?",
        answer:
          "We provide innovative solutions in various industries, focusing on technology and service excellence.",
      },
      {
        question: "Can we expand the story section?",
        answer:
          "Yes, the story section can be expanded with more content and multimedia.",
      },
      {
        question: "Are there opportunities for collaboration?",
        answer:
          "Yes, Goken India seeks collaboration with global partners to deliver exceptional services.",
      },
      {
        question: "How do I get in touch with Goken India?",
        answer:
          "You can reach us via our contact page for inquiries and partnerships.",
      },
    ],
    para: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.",
    linkText: "Contact Us",
    link: "/contactus/",
  },
  footer: {
    id: "footer",
    links: [
      { title: "About Us", url: "#" },
      { title: "Careers", url: "#" },
      { title: "Contact", url: "#" },
      { title: "Privacy Policy", url: "#" },
    ],
  },
};

const GokenIndia = () => {
  const data = useStaticQuery(graphql`
    query GetGIXData {
      gcms {
        servicePages(where: { pageType: GIX }) {
          headerVideo {
            url
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
  `);
  console.log(data.gcms.servicePages[0]);
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/gokenindia/"}
        title="Goken India: Goken India - Expanding Horizons"
        description={
          "Goken India provides innovative engineering solutions and services to drive industry excellence."
        }
        facebookTitle={`Goken India: Goken India - Expanding Horizons`}
        facebookDescription={`Discover Goken India's revolutionary approach to engineering and technology. Explore our innovative solutions that drive efficiency and excellence in the global market. Click to learn more!`}
      />
      <div className="gix_v2_wrapper">
        <HeaderTitle
          heading={pageData?.header?.title}
          para={pageData?.header?.subtitle}
          images={pageData?.header?.images}
        />
        <HalfDescription
          heading={pageData?.latestUpdate?.title}
          para={pageData?.latestUpdate?.description}
          link={pageData?.latestUpdate?.ctaLink}
          linkText={pageData?.latestUpdate?.ctaText}
        />
        <AboutBySwapnil
          image={pageData?.leadership?.image}
          testimonial={pageData?.leadership?.testimonial}
          name={pageData?.leadership?.name}
          designation={pageData?.leadership?.designation}
        />
        <Impact
          title={pageData?.Impact?.title}
          para={pageData?.Impact?.para}
          list={pageData?.Impact?.points}
        />
        <Current
          heading={pageData?.current?.title}
          bgImage={pageData?.current?.bgImage}
          para={pageData?.current?.para}
          images={pageData?.current?.carouselItems}
        />
        <Clients
          heading={pageData?.clients?.title}
          para={pageData?.clients?.para}
          images={pageData?.clients?.images}
          bgImg={pageData?.clients?.bgImage}
        />
        <ImpactFuture
          heading={pageData?.futureImpact?.title}
          list={pageData?.futureImpact?.paras}
          blocks={pageData?.futureImpact?.articles}
          ctaLink={pageData?.futureImpact?.ctaLink}
          ctaText={pageData?.futureImpact?.ctaText}
        />
        <HalfDescription
          heading={pageData?.values?.heading}
          para={pageData?.values?.para}
          link={pageData?.values?.link}
          linkText={pageData?.values?.linkText}
        />
        <AssociateStories
          heading={pageData?.testimonials?.title}
          para={pageData?.testimonials?.para}
          ctaLink={pageData?.testimonials?.ctaLink}
          ctaText={pageData?.testimonials?.ctaText}
          stories={pageData?.testimonials?.profiles}
          sectionHeading={pageData?.testimonials?.sectionHeading}
          playImage={pageData?.testimonials?.playImage}
        />
        <VideoSection
          video={pageData?.videoSection?.videoUrl}
          videoImage={pageData?.videoSection?.image}
          title={pageData?.videoSection?.title}
        />
        <FAQ
          heading={pageData?.faq?.title}
          para={pageData?.faq?.para}
          faqs={pageData?.faq?.questions}
          link={pageData?.faq?.link}
          linkText={pageData?.faq?.linkText}
        />
      </div>
      {/* <div className="gix__page_wrapper"> */}
      {/* <Header
          headingOne={"Goken"}
          headingTwo={"India"}
          first={{ smallTitle: "Established In", bigTitle: "2019" }}
          second={{ smallTitle: "Located In", bigTitle: "Pune India" }}
          bgImage={bgImage}
          mobileImage={mobileImage}
        />
        <OurStory
          preTitle={"Our"}
          heading={"Story"}
          paragraph={`The purpose of setting up Goken’s India office was to offer a strong pillar of support for Goken’s global operations. Goken India’s vision is to establish Goken as a global brand in the areas of software application development and engineering services for the automotive, aerospace and manufacturing sectors. 
          It also supports Goken global operations in multiple ways such as Talent Management, Marketing and 
          Business Development.`}
          btnText={"Work With Us"}
          link={"/contactus/"}
        />
        <AboutImage media={data.gcms.servicePages[0].headerVideo} />
        <OurClients smallHeading={"A few of "} bigHeading={"Our Clients"} /> */}
      {/* <StaffingNewTimelineComponent
          bannerService={"Goken India"}
          
          items={processData}
          style={"staffing"}
        /> */}
      {/* <StaffingDescription
          heading={"Where Goken India adds Value"}
          image={valueImage}
          description={" "}
        /> */}
      {/* <HowWeServeClients />
        <SectionDivider />
        <GokensContribution /> */}
      {/* </div> */}
      {/* <div className="w-90-wrapper">
        <Insights
          insights={data.gcms.servicePages[0].insightPages}
          showTitle={true}
          thumbnails={true}
        />
      </div> */}
    </Layout>
  );
};

export default GokenIndia;
