import React from "react";
import HalfDescription from "./HalfDescription";

const Clients = ({ heading, para, bgImg, images }) => {
  return (
    <div className="gix__clients-section-wrapper">
      <HalfDescription heading={heading} para={para} />
      <div
        className="client-testimonials-section"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "100% 100%",
        }}
      >
        {images?.map(({ image, altText }) => {
          return <img src={image} alt={altText} />;
        })}
      </div>
    </div>
  );
};

export default Clients;
