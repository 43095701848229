import React from "react";
import FooterFAQ from "../../Footer/v2/FooterFAQ";
import WipeBtnBlack from "../../../components/atoms/buttons/WipeBtnBlack";

const FAQ = ({ faqs, heading, para, linkText, link }) => {
  return (
    <div className="gix__faq-section-wrapper w-90-wrapper">
      <div className="left-heading-wrapper">
        <h2 className="semi-page-heading">{heading}</h2>
        <WipeBtnBlack url={link} text={linkText} fixedWidth />
        <p className="para">{para}</p>
      </div>
      <div className="right-faq-wrapper">
        <FooterFAQ lang="in" faqs={faqs} class />
      </div>
    </div>
  );
};

export default FAQ;
