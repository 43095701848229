import React from "react";
import WipeBtnBlack from "../../../components/atoms/buttons/WipeBtnBlack";
import { Link } from "gatsby";

const ImpactFuture = ({ heading, list, blocks, ctaLink, ctaText }) => {
  return (
    <div className="section-br">
      <div className="gix__impact-future-section w-90-wrapper ">
        <h2 className="heading">{heading}</h2>
        <div className="impact-future-section">
          {list?.map((section) => (
            <p className="para fw-400">{section}</p>
          ))}
        </div>
        <div className="blocks-wrapper">
          {blocks?.map(({ heading, para, link, linkText, image, imgAlt }) => (
            <div className="impact-block">
              <div className="left-image-wrapper">
                <img src={image} alt={imgAlt ?? "Goken India Impacts"} />
              </div>
              <div className="right-content-wrapper">
                <Link to={link}>
                  <h2 className="title fw-600 margin-0">{heading}</h2>
                  <p className="para fw-400 margin-0">{para}</p>
                  <Link className="block-read-more fw-500 para" to={link}>
                    {linkText}
                  </Link>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="cta-wrapper">
          <WipeBtnBlack
            url={ctaLink}
            text={ctaText}
            // className={"wipe-btn-auto-width"}
          />
        </div>
      </div>
    </div>
  );
};

export default ImpactFuture;
