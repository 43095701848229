import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

const HeaderTitle = ({ heading, para, images = [] }) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    setInterval(() => {
      console.log(active + 1);

      setActive((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);
  }, []);
  return (
    <div className="gix_header-title-section">
      <div className="gix__content-wrapper">
        <h1
          className="page-heading"
          dangerouslySetInnerHTML={{ __html: heading }}
        ></h1>
        <p className="title fw-600">{para}</p>
      </div>
      <div className="images-wrapper">
        {images?.map((img, index) => {
          return (
            <div
              key={index}
              className={`slide-image ${
                index === active ? "active" : "inactive"
              }`}
            >
              <Link to={img.link ?? ""}>
                <img src={img.image} alt="Goken India Header Image" />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderTitle;
